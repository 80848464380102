import React from "react";

const About = () => {
  return (
    <div
      name="About"
      className="w-full h-full bg-gradient-to-b from-gray-800 to-black text-white "
    >
      <div className="max-w-screen-lg px-2 py-10 mx-auto flex flex-col justify-content w-full h-full animaiteLeft">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>
        <p className="text-xl mt-10">
          As a Software Engineer, I have a passion for building beautifully
          designed and user-friendly websites and web applications. With a
          strong foundation in HTML, CSS, and JavaScript, I am able to create
          visually appealing and interactive user interfaces that meet the needs
          of my clients and their users.
        </p>

        <br />
        <p className="text-xl my-10">
          I have a keen eye for design and a strong attention to detail, which
          allows me to bring designs to life with precision and accuracy. I am
          skilled in responsive web design and can ensure that my projects look
          and function great on all devices.
        </p>
        <p className="text-xl">
          I am always striving to improve my skills and stay up to date with the
          latest web development technologies. I am a team player and enjoy
          collaborating with designers and back-end developers to create
          seamless and cohesive projects.
        </p>
      </div>
    </div>
  );
};

export default About;
