import React from "react";
import html from "../assets/html.png";
import css from "../assets/css.png";
import jsavascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import angular from "../assets/angular.png";
import github from "../assets/git.png";
import tailwind from "../assets/tailwind.png";
import node from "../assets/node.png";
import typescript from "../assets/typescript.png";
import express from "../assets/express.png";
import mongo from "../assets/mongo.svg";
import next from "../assets/nextjs.png";

const Skills = () => {
  const skills = [
    {
      id: 1,
      src: html,
      title: "HTML",
      style: "shadow-orange-500",
    },
    {
      id: 2,
      src: css,
      title: "CSS",
      style: "shadow-blue-500",
    },
    {
      id: 3,
      src: jsavascript,
      title: "Javascript",
      style: "shadow-orange-500",
    },
    {
      id: 4,
      src: reactImage,
      title: "React",
      style: "shadow-blue-600",
    },
    {
      id: 5,
      src: tailwind,
      title: "Tailwind",
      style: "shadow-sky-400",
    },
    {
      id: 6,
      src: angular,
      title: "Angular",
      style: "shadow-red-500",
    },
    {
      id: 7,
      src: typescript,
      title: "Typescript",
      style: "shadow-sky-600",
    },
    {
      id: 8,
      src: github,
      title: "Git",
      style: "shadow-red-400",
    },
    {
      id: 9,
      src: node,
      title: "Node",
      style: "shadow-green-500",
    },
    {
      id: 10,
      src: express,
      title: "Express",
      style: "shadow-gray-400",
    },
    {
      id: 11,
      src: mongo,
      title: "MongoDB",
      style: "shadow-green-600",
    },
    {
      id: 12,
      src: next,
      title: "Next JS",
      style: "shadow-gray-600",
    },
  ];

  return (
    <div
      name="Skills"
      className="bg-gradient-to-b from-gray-800 to-black  w-full h-full"
    >
      <div className="max-w-screen-lg mx-auto p-4 pt-10 flex-flex-col justify-center w-full h-full text-white">
        <div className="pb-8">
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Skills
          </p>
          <p className="py-6">These are the technologies i have worked with</p>
        </div>

        <div className="w-full flex flex-wrap justify-around  items-center gap-8 text-center py-8 px-6 sm:px-0 animaiteDown">
          {skills.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 px-5 py-4 rounded-xl w-32 ${style}`}
            >
              <img src={src} alt="" className="w-10 mx-auto " />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
