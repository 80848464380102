import React from "react";

const Experiance = () => {
  return (
    <div
      name="Experience"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-full"
    >
      <div className="max-w-screen-lg py-4 pt-10 mx-auto flex flex-col justify-center w-full h-full">
        {/* <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500"></p>
          <p className="py-6">Checkout some of my work right here</p>
        </div> */}
        <section className="animaiteRight">
          <div className="container max-w-5xl  py-12 mx-auto">
            <div className="">
              <div className="col-span-12 sm:col-span-3">
                <div className="text-left px-4 sm:px-0 mb-6 ">
                  <h3 className="text-3xl font-bold">Experiance</h3>
                  <hr className="block w-44 h-1 mb-5 rounded-md  sm:mx-0 bg-violet-400 mt-3" />
                  {/* <span className="text-sm font-bold tracking-wider uppercase text-gray-400">
                    Checkout some of my work right here
                  </span> */}
                </div>
              </div>
              <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                <div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-700">
                  <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400">
                    <h3 className="text-xl font-semibold tracking-wide">
                      <p className="my-1">
                        Software Engineer |{" "}
                        <a
                          href="https://www.nowfloats.com/"
                          target="_blank"
                          rel="noreferrer"
                          className=""
                        >
                          Nowfloats Technologies{" "}
                        </a>
                      </p>
                    </h3>

                    <time className="text-xs tracking-wide uppercase text-gray-400 my-2">
                      Nov 2021 - Sept -2023
                    </time>
                    <ul>
                      <li className="mb-3 ml-3 list-disc">
                        Managed and developed a portfolio of 13 thematic
                        websites using HTML, CSS, and JavaScript, ensuring
                        high-quality performance and user experience. Actively
                        debugged and resolved issues swiftly to maintain optimal
                        site functionality
                      </li>
                      <li className="mb-3 ml-3 list-disc">
                        Provided critical support for Boost360, a legacy project
                        built with Knockout JS, ensuring its ongoing success and
                        stability.
                      </li>
                      <li className="mb-3 ml-3 list-disc">
                        Played a significant role in the development of Bizhq,
                        an Angular-based project, where I contributed to the
                        architecture, UI design, and delivery of a
                        client-focused application.
                      </li>
                    </ul>
                    <p className="mt-3"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Experiance;
