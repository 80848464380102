import React from "react";
import wordBeater from "../assets/portfolio/wordbeater.png";
import wordCounter from "../assets/portfolio/wordCounter.png";
import weatherapp from "../assets/portfolio/weatherapp.png";
import qrGenerator from "../assets/portfolio/qrGenerator.png";
import urlShortner from "../assets/portfolio/urlShortner.png";
import BlogApp from "../assets/portfolio/BlogApp.png";
import TaskManager from "../assets/portfolio/TaskManager.png";
import MystryMessagesApp from "../assets/portfolio/MystryMessagesApp.png";

const Projects = () => {
  const projects = [
    {
      id: 8,
      appName: "Mystry Messages ",
      src: MystryMessagesApp,
      codeUrl: "https://github.com/zubair-shabir/mystryMessages",
      demoUrl: "https://mystry-messages-beta.vercel.app/",
      description:
        "A Next.js-based app that lets users receive anonymous messages via a unique URL. Users can log in to their dashboard to view and manage all messages. Perfect for anonymous feedback or open communication while ensuring privacy",
    },
    {
      id: 7,
      appName: "Task Manager ",
      src: TaskManager,
      codeUrl: "https://github.com/zubair-shabir/taskmanager",
      demoUrl: "https://taskmanager-ui-silk.vercel.app/",
      description:
        "This app lets you create, edit, and delete Tasks. Manage your Tasks with ease (React, Express, Node, MongoDB and Bootstrap). !",
    },
    {
      id: 6,
      appName: "Blog App ",
      src: BlogApp,
      codeUrl: "https://github.com/zubair-shabir/blog-app",
      demoUrl: "https://frontendtechblog.netlify.app/",
      description:
        "This app lets you create, edit, and share blog posts. Sign up, log in, and manage your content with ease (React, Appwrite, Tailwind CSS).  Write & share!",
    },
    {
      id: 1,
      src: qrGenerator,
      appName: "QR Code Generator ",
      codeUrl: "https://github.com/zubair-shabir/QrCodeGenerator",
      demoUrl: "https://urlqrcodegenerate.netlify.app/",
      description:
        "This React app lets you create QR codes for any URL. Just enter your info and scan the code with your phone. ",
    },
    {
      id: 2,
      src: urlShortner,
      appName: "URL Shortner App",
      codeUrl: "https://github.com/zubair-shabir/URLShortner",
      demoUrl: "https://zubair-shabir.github.io/URLShortner/",
      description:
        "Cut down lengthy URLs into bite-sized, shareable links. Perfect for social media, emails, and SMS. Track clicks and manage your shortened links.",
    },
    {
      id: 3,
      src: wordCounter,
      appName: "Word Counter App ",
      codeUrl: "https://github.com/zubair-shabir/word_counter",
      demoUrl: "https://zubair-shabir.github.io/word_counter/",
      description:
        "This handy tool keeps track of your word count as you type. Stay on target for essays, articles, or any writing project.",
    },
    {
      id: 4,
      src: weatherapp,
      appName: "Weather App",
      codeUrl: "https://github.com/zubair-shabir/weather_API.github.io",
      demoUrl: "https://zubair-shabir.github.io/weather_API.github.io/",
      description:
        "Enter a location & get real-time temperature & conditions using OpenWeatherMap. ☀️️❄️",
    },
    {
      id: 5,
      src: wordBeater,
      appName: "Typing Test App",
      codeUrl: "https://github.com/zubair-shabir/wordBeatter.github.io",
      demoUrl: "https://zubair-shabir.github.io/wordBeatter.github.io/",
      description: "",
    },
  ];
  return (
    <div
      name="Projects"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-full "
    >
      <div className="max-w-screen-lg p-4 pt-10 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Projects
          </p>
          <p className="py-6">Checkout some of my work right here</p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-6 md:px-0 ">
          {projects.map(
            ({ id, src, codeUrl, demoUrl, description, appName }) => (
              <div
                key={id}
                className="shadow-md shadow-gray-600 rounded-lg animaiteLeft hover:scale-105 transition-all ease-in-out duration-200"
              >
                <div className="relative group">
                  <img
                    src={src}
                    alt=""
                    className="rounded-md duration-200 group-hover:opacity-100 h-36 w-full object-cover "
                  />
                </div>

                <div className="flex items-center justify-center ">
                  <a
                    href={demoUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                  >
                    Demo
                  </a>
                  <a
                    href={codeUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                  >
                    Code
                  </a>
                </div>
                <h2 className="text-center pb-2">{appName}</h2>
                <p
                  className=" px-4  bg-opacity-80 bg-gray-700 text-center text-white line-clamp-4 py-1"
                  title={description}
                >
                  {description}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
