import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

const Footer = () => {
    const links = [
        {
          id: 1,
          child: (
            <>
              <FaLinkedin size={30} />
            </>
          ),
          href: "https://www.linkedin.com/in/zubair-shabir-349094120/",
          style: "rounded-tr-md",
        },
        {
          id: 2,
          child: (
            <>
             <FaGithub size={30} />
            </>
          ),
          href: "https://github.com/zubair-shabir",
        },
        {
          id: 3,
          child: (
            <>
            <HiOutlineMail size={30} />
            </>
          ),
          href: "mailto:zubairshabir980@gmail.com",
        },
       
      ];
  return (
    <div className=" lg:hidden h-fit flex justify-center items-center bg-gray-800 w-full py-10">
       <ul className="flex gap-10">
        {links.map(({ id, child, href, style, download }) => (
          <li
            key={id}
            className={`flex  justify-between items-center w-fit h-14 px-4 rounded-full bg-gray-600  `}
          >
            <a
              href={href}
              className="flex justify-between items-center w-full  text-white"
              download={download}
              target="_blank"
              rel="noreferrer"
            >
              <>{child}</>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Footer;
