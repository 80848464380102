import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [isSending, setIsSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (isSending) return; // If the email is already being sent, do nothing

    setIsSending(true); // Set the flag to true

    emailjs
      .sendForm(
        "service_g2mif3j", //  EmailJS service ID
        "template_7osovmd", //  EmailJS template ID
        e.target,
        { publicKey: "Y0JbsK8mIFKJuM7ww" } // EmailJS user ID
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Email sent successfully!");
          setIsSending(false);
        },
        (error) => {
          console.error("Failed to send email:", error.text);
          alert("Failed to send email. Please try again.");
          setIsSending(false);
        }
      );

    e.target.reset();
  };

  return (
    <div
      name="Contact"
      className="w-full h-full bg-gradient-to-b from-black to-gray-800 p-4 text-white overflow-x-clip"
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto w-full h-full ">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Contact
          </p>
          <p className="py-6">Submit the form below to get in touch with me</p>
        </div>

        <div className="flex justify-center items-center animaiteRight">
          <form onSubmit={sendEmail} className="flex flex-col w-full md:w-1/2">
            <input
              type="text"
              id="from_name"
              required
              name="from_name"
              placeholder="Enter your name"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <input
              type="email"
              id="from_email"
              required
              name="from_email"
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <textarea
              name="message"
              required
              id="message"
              rows="10"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
              placeholder="Enter your message ✍️✍️"
            ></textarea>
            <button
              type="submit"
              className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300"
            >
              {isSending ? "Sending..." : "Let's Talk"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
